// Common styles
.cc-card {
  background-color: $bg-static;
  border-radius: $border-radius-sm;
  display: block;
  padding: 1.5rem 2rem;

  &.cc-card-interactive {
    background-color: $bg-interactive;
  }

  .cc-card-image-header {
    margin: -1.5rem -2rem 1.5rem;
    position: relative;
  }

  .cc-image-heading {
    left: 2rem;
    right: 2rem;
  }

  .cc-card-title {
    @include caps-label($bright);
    margin-bottom: 1rem;

    .cc-icon {
      font-size: 1rem;
    }
  }

  .cc-card-image-status {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }

  .cc-card-heading {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: .5rem;
  }

  .cc-card-subtext {
    margin-bottom: .5rem;
  }

  .cc-card-metric {
    font-size: 2rem;
    line-height: 2.5rem;

    &.cc-card-metric-xs {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &.cc-card-metric-sm {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    &.cc-card-metric-lg {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    &.cc-card-metric-xl {
      font-size: 3rem;
      line-height: 3rem;
    }
  }

  .cc-card-submetric-label {
    @include caps-label();
    margin-bottom: 0;
  }
}

a.cc-card {
  background-color: $bg-interactive;
  color: $white;
  transition: $btn-transition;

  &:hover,
  &:focus {
    background-color: lighten($bg-interactive, 5%);
    text-decoration: none;

    .cc-card-link {
      color: $link-color;
      text-decoration: underline;
    }
  }

  &:active {
    background-color: darken($bg-interactive, 2.5%);
  }
}

.cc-card-list {
  margin-bottom: 0;

  .cc-card {
    height: 100%;
  }
}
