.cc-nav-settings {
  margin-bottom: 2rem;

  .nav-link {
    margin-bottom: .5rem;
    padding: 1.25rem 4rem;
    position: relative;

    .cc-icon {
      color: $muted;
      position: absolute;
      top: 1.5rem;
      left: 2rem;
    }

    .cc-nav-settings-label {
      @include caps-label($white);
    }

    .badge {
      background-color: rgba($black, .4);
      font-size: .875rem;
      line-height: 1rem;
      padding: .25rem .75rem;
      position: absolute;
      right: 3.5rem;
      top: 1.25rem;
    }

    .cc-nav-settings-icon-chevron {
      color: $white;
      right: 1.5rem;
      left: auto;
    }
  }
}

.cc-nav-list {
  .nav-link {
    padding: 1.25rem 2.5rem 1.25rem 2rem;
    width: 100%;

    .cc-nav-list-title {
      display: block;
      font-weight: 500;
      @include text-truncate;
    }

    .cc-nav-list-subtitle {
      display: block;
      @include caps-label-sm;
    }
  }
}
