.cc-wizard-progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;

  &:before {
    content: '';
    background-color: $bg-interactive;
    border-radius: $border-radius;
    display: block;
    height: .25rem;
    position: absolute;
    top: .375rem;
    left: .5rem;
    right: .5rem;
    bottom: .375rem;
  }

  .cc-wizard-step {
    background-color: $muted;
    border-radius: 1rem;
    display: block;
    height: 1rem;
    width: 1rem;
    position: relative;

    &.cc-wizard-step-current {
      background-color: $vivid;
    }

    &.cc-wizard-step-done {
      background-color: $cc-aqua;

      &:hover,
      &:focus {
        background-color: lighten($cc-aqua, 20%);
      }
    }
  }
}

.cc-wizard-subtitle {
  @include caps-label($bright);
  margin-top: 2rem;
}

.cc-wizard-summary-card {
  background-color: $bg-static;
  padding: .5rem 2rem 2rem;

  .cc-wizard-progress,
  .cc-wizard-summary,
  .cc-wizard-proceed {
    margin-top: 1.5rem;
  }
}

.cc-wizard-summary {
  .cc-wizard-summary-item {
    margin-top: 1.5rem;

    .cc-wizard-summary-item-title {
      @include caps-label();
      margin-bottom: 0;
    }

    .cc-wizard-summary-item-value {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .cc-wizard-summary-card {
    .cc-wizard-progress {
      display: none;
    }
  }

  .cc-wizard-proceed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .btn {
      border-radius: 0;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cc-page-header {
    .cc-wizard-progress {
      display: none;
    }
  }

  body.cc-layout-wizard {
    .cc-sidebar {
      .cc-wizard-summary-card {
        position: fixed;
        width: 20rem;
      }
    }
  }
}

.cc-wizard-option-group {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}

.cc-wizard-option {
  @include bg-interactive;
  margin-bottom: .5rem;
  padding: 1rem 1.5rem;
}
