// Status colours
$status-live: $cc-blue;
$status-live-text: $cc-blue-light;

$status-submitted: $cc-orange;
$status-submitted-text: $cc-orange-light;

$status-uplift: $cc-green;
$status-uplift-text: $cc-green-light;

$status-reduction: $cc-red;
$status-reduction-text: $cc-red-light;

@mixin status($status: $cc-neutral-900, $status-text: $cc-neutral-600) {
  @include caps-label($status-text);
  background-color: rgba($status, .8);
  border-radius: $border-radius-sm;
  display: inline-block;
  height: 2rem;
  padding: .25rem .5rem;
  vertical-align: top;
  white-space: nowrap;

  .cc-icon {
    color: rgba($status-text, .7);
    font-size: 1rem;
    line-height: 1.5rem;
    vertical-align: -.1875em;
  }
}

.cc-status-live {
  @include status($status-live, $status-live-text);
}

.cc-status-submitted {
  @include status($status-submitted, $status-submitted-text);
}

.cc-status-uplift {
  @include status($status-uplift, $status-uplift-text);
}

.cc-status-reduction {
  @include status($status-reduction, $status-reduction-text);
}
