.cc-signin {
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;

  .cc-brand {
    margin-bottom: 3rem;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .cc-signin-form {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }

  .cc-signin-buttons {
    .btn {
      margin-bottom: 1.5rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .cc-signin-label {
    @include caps-label;
    margin-bottom: .5rem;
  }

  .cc-signin-title {
    margin-bottom: 2rem;
    text-align: center;
  }
}
