$font-features: "ss02";

body {
  font-feature-settings: $font-features;
}

// Headings
h1, .h1 {
  letter-spacing: -.0125em;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
}

h2, .h2 {
  letter-spacing: -.0125em;
  line-height: 2.25rem;
}

h3, .h3 {
  letter-spacing: -.0125em;
  line-height: 2rem;
}

h4, .h4 {
  letter-spacing: -.0125em;
  line-height: 1.75rem;
}

h5, .h5 {
  letter-spacing: -.0125em;
  line-height: 1.5rem;
}

h6, .h6 {
  letter-spacing: -.0125em;
  line-height: 1.5rem;
}

// ALL CAPS labels
@mixin caps-label($color: $muted, $size: .875rem, $line-height: 1.5rem, $weight: 500) {
  color: $color;
  font-family: $font-family-sans-serif;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: .0625rem;
  line-height: $line-height;
  text-transform: uppercase;

  .cc-icon {
    font-size: 1rem;
  }
}

@mixin caps-label-sm($color: $muted) {
  @include caps-label($color, .75rem, 1.5rem, 400);
}

.cc-caps-label {
  @include caps-label();
}

.cc-caps-label-sm {
  @include caps-label-sm($muted);
}

.cc-date {
  @include caps-label($muted);
}

.cc-store {
  color: $muted;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: right;
}

.cc-copyright {
  color: $muted;
  font-size: .875rem;
  line-height: 1.5rem;
  margin-top: 3rem;
  text-align: center;

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.cc-store-address {
  white-space: pre;
}
