.btn {
  @include caps-label($white);
}

@mixin button-shadow($color: rgba($cc-neutral-900, .1), $width: .25rem) {
  // box-shadow: inset 0 -.25rem 0 $color;
  // background-image: linear-gradient(180deg, rgba($white, .1), rgba($white, .1) #{$width}, transparent calc(100% - #{$width}), transparent 100%);
  // background-image: linear-gradient(180deg, transparent 0%, transparent calc(100% - #{$width}), $color calc(100% - #{$width}), $color 100%),
  //                   linear-gradient(180deg, rgba($white, .05), rgba($white, .05) #{$width}, transparent 100%);
  background-image: linear-gradient(180deg, transparent 0%, transparent calc(100% - #{$width}), $color calc(100% - #{$width}), $color 100%),
                    linear-gradient(180deg, rgba($bright, .1), rgba($bright, .1) #{$width}, transparent 100%);
}

.btn {
  &:not([disabled]) {
    &:hover,
    &:focus {
      transform: translateY(-.125rem);
    }

    &:active {
      transform: none;
    }
  }

  &[disabled],
  &.disabled {
    cursor: default;
  }

  .cc-btn-icon {
    font-size: 1rem;
    opacity: .7;
    vertical-align: -.1875em;
  }
}

.btn-primary {
  @include button-shadow();
  @include button-variant($primary, $primary, lighten($primary, 7.5%), lighten($primary, 7.5%), darken($primary, 5%), darken($primary, 5%));
}

.btn-secondary {
  @include button-shadow();
  @include button-variant($secondary, $secondary, lighten($secondary, 5%), lighten($secondary, 5%), darken($secondary, 2.5%), darken($secondary, 2.5%));
}

.btn-info {
  @include button-variant($info, $info, lighten($info, 5%), lighten($info, 5%), darken($info, 5%), darken($info, 2.5%));
}

.btn-rounded {
  border-radius: 50rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}
