.cc-table-dayparting {
  table-layout: fixed;
  max-width: 24rem;
  width: 100%;

  th,
  td {
    text-align: center;
  }

  .cc-table-dayparting-col-time {
    width: 5rem;
  }

  .cc-table-dayparting-button {
    @include caps-label-sm($white);
    background-color: transparent;
    border-radius: $border-radius;
    border-style: none;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1rem;
    margin-left: -.25rem;
    margin-right: -.25rem;
    padding: .25rem;
    transition: $btn-transition;

    &:hover,
    &:focus {
      background-color: $cc-neutral-600;
      color: $white;
      outline: none;
    }

    &:focus {
      box-shadow: 0 0 0 .25rem rgba($cc-neutral-600, .25);
    }

    &:active {
      background-color: $cc-neutral-500;
    }
  }

  .custom-control {
    min-height: 2rem;
    padding: 0;
    position: relative;

    .custom-control-label {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &:before,
      &:after {
        left: 50%;
        transform: translateX(-50%);
        top: .5rem;
      }
    }
  }
}

.js-alert-hidden {
  display: none;
}
