// Common styles
.cc-navbar {
  @include bg-static();

  .nav-link {
    color: $white;

    .cc-icon {
      color: $muted;
    }

    &:not(.active) {
      &:hover,
      &:focus,
      &:active {
        .cc-icon {
          color: $white;
        }
      }
    }

    &.active {
      color: $white;

      .cc-icon {
        color: $vivid;
      }
    }

    &.cc-navbar-create {
      .cc-icon {
        color: $white;
      }
    }
  }
}

// Mobile styles
@include media-breakpoint-down(md) {
  .cc-navbar {
    border-top: 1px solid $cc-neutral-600;
    padding: 1rem 1.5rem 2rem;
    position: relative;

    .nav {
      display: flex;
      justify-content: space-evenly;
      margin: 0 auto;
      max-width: 32rem;
    }

    .nav-link {
      border-radius: 10rem;
      line-height: 1.5rem;
      padding: .75rem 0;
      text-align: center;
      height: 3rem;
      width: 3rem;

      .cc-icon {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }

    .cc-navbar-home {
      order: 1;
    }

    .cc-navbar-stores {
      order: 4;
    }

    .cc-navbar-create {
      background-image: none;
      order: 3;
    }

    .cc-navbar-analytics {
      order: 2;
    }

    .cc-navbar-settings {
      order: 5;
    }

    .cc-brand,
    .cc-nav-label {
      display: none;
    }
  }
}

// Desktop
@include media-breakpoint-up(lg) {
  .cc-navbar {
    border-top-style: none;
    padding: 1.5rem;

    .cc-brand {
      display: block;
      margin-bottom: 2rem;
      margin-top: 2rem;

      &.cc-brand-cnc {
        margin-top: 3rem;
      }

      img {
        display: block;
        margin: 0 auto;
        max-height: 6rem;
        max-width: 100%;
      }
    }

    .nav {
      flex-direction: column;
    }

    .nav-link {
      @include caps-label($white);
      margin-bottom: .5rem;
      margin-top: .5rem;
      padding: .75rem .75rem .75rem 3rem;
      position: relative;

      .cc-nav-label {
        display: block;
      }

      .cc-icon {
        font-size: 1.3125rem;
        line-height: 1.5rem;
        position: absolute;
        left: .75rem;
        top: .875rem;
      }
    }

    .cc-navbar-create {
      @include button-shadow();
      text-align: left;
    }
  }
}
