.cc-piechart {
  position: relative;
  margin: -1rem auto 0;
  max-width: 7rem;

  .cc-piechart-text {
    display: inline;
    font-size: 1.25rem;
    line-height: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}

.cc-linechart {
  display: block;
}

.cc-linechart-labels {
  margin-left: -.5rem;
  // margin-right: -1rem;
}

.cc-chart-table {
  width: 100%;

  th,
  td {
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  tr {
    td {
      background-color: darken($cc-neutral-900, 1%);
    }
  }

  tr:nth-of-type(odd) {
    td {
      background-color: lighten($cc-neutral-900, 2%);
    }
  }
}
