// Adds nice animation to dropdown menus

.dropdown-menu {
  display: block !important;
  opacity: 0;
  transition-property: opacity, transform, visibility;
  transition-duration: .25s;
  transition-timing-function: ease-in-out;
  transform: translate3d(0, .5rem, 0);
  visibility: hidden;

  &.show {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
