// Primary
$cc-amaranth: #E92053;
$cc-lilac: #9984AD;
$cc-aqua: #1ABE9E;
$cc-sky: #12B8E1;
$cc-pink: #FF8095;

// Statuses
$cc-blue: #1740D3;
$cc-green: #007A68;
$cc-red: #C42F3E;
$cc-orange: #BD4E04;
$cc-cyan: #0088AA;

$cc-blue-light: #DEE7FC;
$cc-green-light: #D6F7EF;
$cc-red-light: #FFE3E4;
$cc-orange-light: #FFF3DB;
$cc-cyan-light: #DEF8FC;

// Neutral
$cc-neutral-100: #EBECF5;
$cc-neutral-200: #D7D8E3;
$cc-neutral-300: #B9BACC;
$cc-neutral-400: #9191A9;
$cc-neutral-500: #6A6985;
$cc-neutral-600: #4A476B;
$cc-neutral-700: #262345;
$cc-neutral-800: #16122E;
$cc-neutral-900: #0D091F;
