.cc-creative {
  margin-bottom: 2rem;

  .custom-control {
    padding-left: 0;

    .custom-control-label {
      display: block;

      &:before,
      &:after {
        top: .5rem;
        left: .5rem;
      }

      &:after {
        top: .5rem;
        left: .5rem;
      }
    }
  }

  &.cc-creative-selected {
    img {
      box-shadow: 0 0 0 .0625rem $cc-aqua, 0 0 0 .375rem rgba($cc-aqua, .25);
    }
  }

  .cc-creative-preview {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }

  img {
    display: block;
    margin-bottom: .75rem;
    width: 100%;
    border-radius: $border-radius;
    transition: $input-transition;
  }
}
