// Common styles
.cc-image-header {
  background-color: $bg-static;
  background-size: cover;
  background-position: center center;
  margin-bottom: 2rem;
  position: relative;

  .cc-image-header-content {
    background-color: $bg-overlay;
    min-height: 10rem;
    padding: 1.5rem;
  }

  .cc-image-header-brand img {
    display: block;
    margin: 1.5rem auto;
    max-height: 6rem;
    max-width: 75%;
  }

  .cc-image-heading {
    position: absolute;
    bottom: 1.5rem;
    left: 2rem;
    right: 2rem;
  }

  .cc-image-heading-text {
    margin-bottom: 0;
    text-shadow: .125rem .125rem 0 $bg-overlay;
  }

  .cc-image-heading-subtext {
    color: $white;
    margin-top: .75rem;
    text-shadow: .125rem .125rem 0 $bg-overlay;
  }

  .cc-store {
    color: $white;
  }

  .cc-status {
    margin-right: .5rem;
    text-shadow: none;
    vertical-align: baseline;
  }

  &.cc-page-image-header {
    .cc-image-header-content {
      min-height: 18rem;
    }
  }
}

// Mobile styles
@include media-breakpoint-down(xs) {
  .cc-page-image-header {
    .cc-image-heading {
      left: 1.5rem;
      right: 1.5rem;
    }
  }
}

// Tablet styles
@include media-breakpoint-up(sm) {
  .cc-image-header {
    border-radius: $border-radius-sm;
  }
}

// Desktop styles
@include media-breakpoint-up(lg) {
  .cc-image-header {
    .cc-image-header-brand {
      display: none;
    }

    &.cc-page-image-header {
      .cc-image-header-content {
        min-height: 16rem;
      }
    }
  }
}
