// Colours
$bg-interactive: $cc-neutral-700;
$bg-static: $cc-neutral-900;
$bg-overlay: rgba($cc-neutral-900, .5);

$vivid: $cc-amaranth;
$bright: $cc-pink;
$muted: $cc-lilac;

@mixin bg-static {
  background-color: $bg-static;
  border-radius: $border-radius-sm;
}

@mixin bg-interactive {
  background-color: $bg-interactive;
  border-radius: $border-radius-sm;
}
