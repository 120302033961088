$page-margin-y: 4rem;
$help-btn-margin-y: 1.5rem;
$navbar-height: 6rem;
$proceed-btn-height: 4.5rem;

body.cc-layout-nonavpage {
  padding: 1.5rem .5rem 4rem .5rem;

  .cc-help-button {
    position: fixed;
    bottom: $help-btn-margin-y;
    right: 1rem;
  }

  .cc-sidebar {
    display: static;
  }
}

body.cc-layout-wizard,
body.cc-layout-wizard-final {
  padding-bottom: $proceed-btn-height + $page-margin-y;

  .cc-help-button {
    bottom: $proceed-btn-height + $help-btn-margin-y;
  }
}

body.cc-layout-navpage {
  padding: 1.5rem .5rem ($navbar-height + $page-margin-y) .5rem;

  .cc-sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
  }

  .cc-help-button {
    position: fixed;
    bottom: $navbar-height + $help-btn-margin-y;
    right: 1rem;
  }
}

.cc-brand-footer {
  margin-top: 3rem;
  margin-bottom: 3rem;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 6rem;
    max-width: 12rem;
  }
}

@include media-breakpoint-down(xs) {
  body.cc-layout-navpage {
    .cc-page-image-header {
      margin: -1.5rem -1.5rem 2rem;
    }
  }
}

// Desktop styles
@include media-breakpoint-up(lg) {
  body.cc-layout-nonavpage {
    .cc-parent {
      display: grid;
      grid-template-columns: 1fr 20rem;
      grid-template-rows: 1fr;
      grid-column-gap: 3rem;
      grid-row-gap: 0;

      .cc-content {
        grid-area: 1 / 1 / 2 / 2;
        position: static;
      }

      .cc-sidebar {
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    .cc-page-image-header {
      .cc-image-header-content {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .cc-image-heading {
        left: 2rem;
        right: 2rem;
      }
    }
  }

  body.cc-layout-navpage {
    padding: 2rem 0 3rem;

    .cc-parent {
      display: grid;
      grid-template-columns: 15rem 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 3rem;
      grid-row-gap: 0;

      .cc-sidebar {
        grid-area: 1 / 1 / 2 / 2;
        position: static;
      }

      .cc-content {
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    .cc-help-button {
      position: fixed;
      bottom: 3rem;
      right: 3rem;
      z-index: 600;
    }

    .cc-brand-footer {
      display: none;
    }

    .cc-page-image-header {
      .cc-image-header-content {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .cc-image-heading {
        left: 2rem;
        right: 2rem;
      }
    }
  }

  body.cc-layout-wizard .cc-help-button {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 600;
  }
}
