// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff;
$gray-100: $cc-neutral-100;
$gray-200: $cc-neutral-200;
$gray-300: $cc-neutral-300;
$gray-400: $cc-neutral-400;
$gray-500: $cc-neutral-500;
$gray-600: $cc-neutral-600;
$gray-700: $cc-neutral-700;
$gray-800: $cc-neutral-800;
$gray-900: $cc-neutral-900;
$black:    #000;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    $cc-blue;
$pink:    $cc-amaranth;
$red:     $cc-red;
$yellow:  $cc-orange;
$green:   $cc-aqua;
$teal:    $cc-sky;
$cyan:    $cc-cyan;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "pink":       $pink,
    "red":        $red,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $cc-amaranth;
$secondary:     $gray-700;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "bright":     $bright,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

$enable-caret:                                false;
$enable-print-styles:                         false;

$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 2.5),
    7: ($spacer * 3)
  ),
  $spacers
);

$grid-gutter-width:           32px;

$line-height-lg:              1.3333333333; // * 18 = 24px
$line-height-sm:              1.1428571429; // * 14 = 16px

$border-radius:               .125rem;
$border-radius-lg:            $border-radius;
$border-radius-sm:            $border-radius;

$white:    #fff;
$black:    #000;

$body-bg:                   $gray-800;
$body-color:                $white;

$component-active-bg:       $cc-blue;

$link-color:                              $cc-sky;

$link-hover-color:                        lighten($link-color, 5%);

$font-family-sans-serif:      "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif:           "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.125;
$font-size-sm:                $font-size-base * .875;

$h1-font-size:                $font-size-base * 2.25;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base * 1.125;

$headings-margin-bottom:      1rem;
$headings-font-family:        $font-family-serif;
$headings-font-weight:        700;
$headings-line-height:        1.125;

$small-font-size:             87.5%;

$text-muted:                  $muted;

$paragraph-margin-bottom:     1.5rem;

$input-btn-padding-y:         .5rem;
$input-btn-padding-y-sm:      .5rem;
$input-btn-padding-y-lg:      .75rem;
// $input-btn-focus-color:       rgba($cc-aqua, .25);
$input-btn-focus-width:       .25rem;
$input-btn-focus-color:       rgba($cc-aqua, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;


// Forms

$label-margin-bottom:                   0;

$input-padding-y:                       .4375rem;
$input-padding-y-sm:                    .4375rem;
$input-padding-y-lg:                    .6875rem;

$input-bg:                              $gray-600;
$input-disabled-bg:                     $gray-700;
$input-color:                           $white;
$input-border-color:                    $gray-500;
$input-focus-border-color:              $cc-aqua;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;
$input-placeholder-color:               $gray-300;
$input-group-addon-bg:                  darken($input-bg, 7.5%);

$btn-border-width:            0;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, transform .05s ease-in-out;

$dropdown-min-width:                12rem;
$dropdown-padding-y:                .75rem;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $gray-600;
$dropdown-border-color:             transparent;
$dropdown-divider-bg:               $gray-700;
$dropdown-box-shadow:               0 .0625rem .125rem 0 rgba($black, .25), 0 .25rem 1rem 0 rgba($black, .25);

$dropdown-link-color:               $white;
$dropdown-link-hover-color:         $link-hover-color;
$dropdown-link-hover-bg:            lighten($dropdown-bg, 5%);

$dropdown-link-active-bg:           darken($dropdown-bg, 5%);

$dropdown-link-disabled-color:      $gray-400;

$dropdown-item-padding-y:           .5rem;
$dropdown-item-padding-x:           2rem;

$dropdown-header-color:             $muted;

$custom-control-indicator-checked-bg:           $cc-aqua;
$custom-control-indicator-checked-disabled-bg:  rgba($cc-aqua, .5);
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

// $custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
// $custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-bg:            lighten($custom-control-indicator-checked-bg, 25%);
$custom-control-indicator-active-box-shadow:    none;
// $custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;

$alert-bg-level:                    8;
$alert-border-level:                7;
$alert-color-level:                 -10;
